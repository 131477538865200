<template>
  <div>
    <div class="is-flex is-justify-content-center">
      <h4 class="is-title">Choose your billing</h4>
    </div>
    <div class="switch-package columns">
      <div
        v-for="(pack, id) in listPackage"
        :key="id"
        @click="selectIndex(pack, id)"
        class="column has-text-centered package-btn my-1 mx-1"
        :class="[activeIndex === id ? 'active-package' : '']"
      >
        {{ pack }}
      </div>
    </div>

    <div v-if="isLoading" class="columns is-centered mt-6">
      <div class="column is-narrow">
        <div class="loader is-loading">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>

    <carousel
      v-if="!isLoading && packages.length > 0"
      :per-page="4"
      ref="carousel"
    >
      <slide
        v-for="(list, id) in packages"
        :key="id"
        @click="selectPackage(id)"
      >
        <div
          :class="[
            list.tags[0]
              ? 'is-package mt-6 is-package-set-color-active'
              : 'is-package mt-6 is-package-set-color-default',
          ]"
        >
          <div v-if="list.tags[0]" class="best-deals">
            &#x1F525; {{ list.tags[0].name }}
          </div>
          <div
            class="header-card-default"
            v-if="list.is_discount == 1 && list.current_discount"
          >
            <div class="sale-tag has-text-centered">
              {{ list.current_discount ? list.current_discount.amount : '' }}%
            </div>
            <h3 class="title-package-card">{{ list.name }}</h3>
            <div class="sale-price" v-if="list.price !== 0">
              {{ formatCurrency(list.price) }}
            </div>
            <div class="price-package-card">
              <span>
                {{
                  list.total_price === 0
                    ? 'Rp xxx'
                    : formatCurrency(list.total_price)
                }}
              </span>
              <span class="per-month">/month</span>
            </div>
            <div class="desc-text">{{ list.description_1 }}</div>
          </div>

          <div class="header-card-default" v-else>
            <h3 class="title-package-card">{{ list.name }}</h3>
            <div class="price-package-card">
              <span>
                {{
                  list.total_price === 0 ? 'Rp xxx' : formatCurrency(list.price)
                }}
              </span>
              <span class="per-month">/month</span>
            </div>
            <div class="desc-text">{{ list.description_1 }}</div>
          </div>

          <div class="content-package">
            <p class="">{{ list.description_2 }}</p>
          </div>

          <div class="is-flex user-capacity mt-1">
            <b-icon
              class="mr-2"
              type="is-success"
              icon="account-supervisor-outline"
              size=""
            ></b-icon>
            <p>{{ list.max_user === 0 ? 'Custom Max User' : list.max_user }}</p>
          </div>

          <div class="buttons">
            <b-button
              @click="choosePackage(list.id)"
              :class="[
                list.tags[0]
                  ? 'expand-button expand-button-set-color-active'
                  : 'expand-button expand-button-set-color-default',
              ]"
              class="expand-button expand-button-set-color-default"
              :disabled="
                activePackage && activePackage.currentPackage.id === list.id
              "
              :loading="isLoading"
            >
              Choose Package
            </b-button>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import axios from 'axios'
import { showToast } from '../../../services/util'
export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      subscription_type: 'monthly',
      activeIndex: 0,
      activeCardId: 0,
      listPackage: ['Monthly', 'Annual'],
      packages: null,
      isLoading: true,
      activePackage: null,
    }
  },
  async beforeMount() {
    try {
      this.isLoading = true
      await this.getPackages()
      await this.loadCurrentPackage()
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        document.getElementsByClassName(
          'VueCarousel-inner'
        )[0].style.visibility = 'visible'
      }, 10)
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentPackage: 'subscription/getCurrentPackage',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadCurrentPackage: 'subscription/fetchCurrentPackage',
    }),

    /**
     * Format user profile picture by gender
     * @param {String} value - user data
     * @return {String} - formatted string
     */
    formatCurrency(value) {
      return `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },

    /**
     * Handle select index
     * @param {string} id - id index
     * @param {string} pack - type package annualy/monthly
     */
    selectIndex(pack, id) {
      this.activeIndex = id
      this.getPackages(pack)
    },

    /**
     * Fetch Package by type (Annualy/Monthly)
     * @param {string} pack - type pack annualy/monthly
     */
    async getPackages(pack) {
      try {
        const subscriptionType = pack
          ? pack.toLowerCase()
          : this.subscription_type
        const response = await axios.get(
          `/api/space-roketin/payment/v1/package/subscription?subscription_type=${subscriptionType}`
        )
        this.packages = response.data.data
      } catch (error) {
        console.error(error)
      }
    },

    /**
     * Handle select package
     * @param {string} id - id index
     */
    selectPackage(id) {
      this.activeCardId = id
    },

    /**
     * Handle choose package
     * @param {string} packageId - id index
     */
    choosePackage(packageId) {
      // this.getPackageDetail(id)
      this.$emit('choosePackage', packageId)
    },

    /**
     * Fetch Current Package
     */
    async loadCurrentPackage() {
      try {
        let response = await this.actionLoadCurrentPackage()
        if (response && response.status === 200) {
          this.activePackage = response.data.data
        }
      } catch (e) {
        showToast(e.response.meta.message, 'is-danger', 'is-top')
      }
    },
  },
}
</script>

<style></style>
